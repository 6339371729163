import createVueComponent from '../createVueComponent';
export default createVueComponent('id-badge', 'IconIdBadge', [
  [
    'path',
    {
      d: 'M5 3m0 3a3 3 0 0 1 3 -3h8a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-8a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M10 6h4', key: 'svg-2' }],
  ['path', { d: 'M9 18h6', key: 'svg-3' }],
]);
