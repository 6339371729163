import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-nodejs', 'IconBrandNodejs', [
  [
    'path',
    {
      d: 'M9 9v8.044a2 2 0 0 1 -2.996 1.734l-1.568 -.9a3 3 0 0 1 -1.436 -2.561v-6.635a3 3 0 0 1 1.436 -2.56l6 -3.667a3 3 0 0 1 3.128 0l6 3.667a3 3 0 0 1 1.436 2.561v6.634a3 3 0 0 1 -1.436 2.56l-6 3.667a3 3 0 0 1 -3.128 0',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 9h-3.5a1.5 1.5 0 0 0 0 3h2a1.5 1.5 0 0 1 0 3h-3.5',
      key: 'svg-1',
    },
  ],
]);
