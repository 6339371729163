import createVueComponent from '../createVueComponent';
export default createVueComponent('at', 'IconAt', [
  ['path', { d: 'M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28',
      key: 'svg-1',
    },
  ],
]);
